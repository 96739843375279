import styles from "./Header.module.scss";

import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Translation, useTranslation } from "next-i18next";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { useAuth } from "services/auth.service";
import { useThemeContext } from "utils/themeContextWrapper";
import BurgerMenu from "components/BurgerMenu/BurgerMenu";
import { Button } from "components/shared/Button/Button";
import Logo from "components/shared/Logo/Logo";

import IconBurger from "public/img/burger-menu.svg";
import IconMoon from "public/img/icon-moon.svg";
import IconSun from "public/img/icon-sun.svg";
import IconArrow from "public/img/icon-arrow-down-small.svg";
import DropdownArrow from "public/img/dropdown-arrow.svg";
import IconWallet from "public/img/icon-wallet.svg";
import IconRefresh from "public/img/icon-refresh.svg";
import IconRefreshSquared from "public/img/icon-refresh-squared.svg";
import IconStaking from "public/img/icon-stake.svg";
import IconDollar from "public/img/icon-dollar-in-circle.svg";
import IconCube from "public/img/icon-cube.svg";
import IconMerchant from "public/img/Merchant.svg";
import IconMassPayout from "public/img/icon-mass-payout.svg";
import LanguageSelectSecond from "../../shared/LanguageSelectSecond/index";
import IconProcessing from "public/img/precessing-icon.svg";
import IconCatalog from "public/img/icon-catalog.svg";
import { useClickOutside } from "../../../utils/hooks/useClickOutside";
import { useScrollLock } from "utils/hooks/useScrollLock";
import { getLocaleLink } from "../../../utils/getLocaleLink";

interface IService {
  title: string;
  description: string;
  icon: JSX.Element;
  link: string;
}

export interface IServiceList {
  personal: IService[];
  business: IService[];
}

const serviceList: IServiceList = {
  personal: [
    {
      title: "Wallet",
      description: "Your crypto deserves the best – keep it secure and enjoy fast transfers",
      icon: <IconWallet />,
      link: "/wallet",
    },
    {
      title: "P2P Trade",
      description: "P2PTradeDescription",
      icon: <IconRefreshSquared />,
      link: "https://p2p.cryptomus.com",
    },
    {
      title: "Staking",
      description: "Maximize your earnings with our crypto staking and gain from 5% APY",
      icon: <IconStaking />,
      link: "/staking",
    },
    {
      title: "Converter",
      description: "Get the best rates – convert your crypto with real-time accuracy and speed",
      icon: <IconRefresh />,
      link: "/convert",
    },
    {
      title: "Earn",
      description: "Start earning crypto with us today and unlock your potential",
      icon: <IconDollar />,
      link: "/earn",
    },
    {
      title: "Explorer",
      description: "Verifying transactions made easy with Cryptomus Blockchain Explorer",
      icon: <IconCube />,
      link: "/explorer",
    },
  ],
  business: [
    {
      title: "White Label Gateway",
      description: "WhiteLabelDescription",
      icon: <IconMerchant />,
      link: "/white_label",
    },
    {
      title: "Crypto Processing",
      description:
        "All of our sophisticated processing features conveniently compiled and presented for your business",
      icon: <IconProcessing />,
      link: "/processing",
    },
    {
      title: "Merchants directory",
      description: "Place your products in one of the categories and attract new customers",
      icon: <IconCatalog />,
      link: "https://directory.cryptomus.com/",
    },
    {
      title: "Mass Payout",
      description: "Make bulk payouts",
      icon: <IconMassPayout />,
      link: "/mass-payout",
    },
  ],
};

export interface ISnackBar {
  isOpen: boolean;
  language: string;
}

interface IServiceDropdownProps {
  setServicesOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IServiceItem {
  service: IService;
}

const ServiceItem = ({ service }: IServiceItem) => {
  const router = useRouter();
  const { t, i18n } = useTranslation("common");

  const getServiceLink = (link: string) => {
    if (link.includes("cryptomus")) {
      return link.replace(".com", `.com/${i18n.language}`);
    } else {
      return link;
    }
  };

  return (
    <li className={cn({ [styles.active]: router.pathname === service.link })}>
      <Link
        href={getServiceLink(service.link)}
        className={styles.service_item}
        lang={i18n.language}
      >
        <div className={styles.icon}>{service.icon}</div>
        <div className={styles.service_info}>
          <p>{t(`header.${service.title}`)}</p>
          <p>{t(`header.${service.description}`)}</p>
        </div>
      </Link>
    </li>
  );
};

const ServiceDropdown = ({ setServicesOpen }: IServiceDropdownProps) => {
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const [personalOpen, setPersonalOpen] = useState<boolean>(false);
  const [businessOpen, setBusinessOpen] = useState<boolean>(false);

  const types = [
    {
      title: "Personal use",
      action: setPersonalOpen,
      isOpen: personalOpen,
      serviceList: serviceList.personal,
    },
    {
      title: "Business use",
      action: setBusinessOpen,
      isOpen: businessOpen,
      serviceList: serviceList.business,
    },
  ];

  useEffect(() => {
    setPersonalOpen(false);
    setBusinessOpen(false);
  }, [router]);

  return (
    <ul className={styles.type_list}>
      {types.map((type) => (
        <li key={type.title} className={styles.type_border}>
          <div
            className={cn(styles.type_item, {
              [styles.active]: type.serviceList.some((item) => item.link === router.pathname),
            })}
            onMouseEnter={() => type.action(true)}
            onMouseLeave={() => type.action(false)}
          >
            <span>{t(`header.${type.title}`)}</span>
            <IconArrow />
          </div>
          {type.isOpen && (
            <div
              className={styles.service_list_container}
              onMouseEnter={() => {
                type.action(true);
                setServicesOpen(true);
              }}
            >
              <ul className={styles.service_list} onMouseLeave={() => type.action(false)}>
                {type.serviceList.map((service) => (
                  <ServiceItem service={service} key={service.title} />
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
      <div className={styles.dropdown_arrow}>
        <DropdownArrow />
      </div>
    </ul>
  );
};

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const { isAuth } = useAuth();
  const router = useRouter();
  const { locale } = router;
  const { theme, toggleTheme } = useThemeContext();
  const serviceRef = useRef<HTMLDivElement>(null);

  const [openBurger, setOpenBurger] = useState<boolean>(false);
  const [servicesOpen, setServicesOpen] = useState<boolean>(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    setServicesOpen(false);
    setOpenBurger(false);
  }, [router]);

  const handleCloseService = () => {
    setServicesOpen(false);
  };

  useEffect(() => {
    if (openBurger) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [openBurger]);

  useClickOutside(serviceRef, handleCloseService);

  return (
    <Translation>
      {(t) => (
        <header className={styles.container}>
          <div className={styles.header}>
            <Logo />
            <div className={styles.navbar}>
              <div className={styles.navigation}>
                <div className={styles.services} ref={serviceRef}>
                  <div
                    className={cn(styles.services_title, { [styles.open]: servicesOpen })}
                    onClick={() => setServicesOpen(!servicesOpen)}
                  >
                    <span>{t("header.Services")}</span>
                    <IconArrow />
                  </div>
                  {servicesOpen && (
                    <div className={styles.type_list_container}>
                      <ServiceDropdown setServicesOpen={setServicesOpen} />
                    </div>
                  )}
                </div>
                <Link
                  href="/tariffs"
                  className={cn({ [styles.active]: router.pathname === "/tariffs" })}
                >
                  {t("header.Tariffs")}
                </Link>
                <Link href="/blog" className={cn({ [styles.active]: router.pathname === "/blog" })}>
                  {t("header.Blog")}
                </Link>
                <Link
                  href={getLocaleLink("https://doc.cryptomus.com/", locale)}
                  target="_blank"
                  rel="noreferrer"
                >
                  API
                </Link>
              </div>
              <div className={styles.settings}>
                <button id="theme__button" aria-label="theme-selector" onClick={toggleTheme}>
                  <motion.span
                    key={theme}
                    initial={{
                      y: 15,
                      opacity: 0,
                    }}
                    exit={{ y: 15, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                    }}
                    transition={{
                      duration: 0.15,
                    }}
                  >
                    {theme === "dark" ? <IconSun /> : <IconMoon />}
                  </motion.span>
                </button>

                <LanguageSelectSecond />
              </div>
              <div className={styles.buttons}>
                {isAuth ? (
                  <Button
                    white
                    linkProps={{
                      type: "external",
                      href: getLocaleLink("https://app.cryptomus.com/dashboard", i18n.language),
                    }}
                  >
                    {t("Go to dashboard")}
                  </Button>
                ) : (
                  <>
                    <div className={styles.header_btn}>
                      <Button
                        appearance="secondary"
                        white
                        fullWidth
                        linkProps={{
                          type: "external",
                          href: getLocaleLink("https://app.cryptomus.com/login", i18n.language),
                        }}
                      >
                        {t("header.Login")}
                      </Button>
                    </div>
                    <div className={styles.header_btn}>
                      <Button
                        white
                        fullWidth
                        linkProps={{
                          type: "external",
                          href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
                        }}
                      >
                        {t("Sign Up")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <button
                id="burger__button"
                aria-label="burger-menu"
                className={styles.burgerWrapper}
                onClick={() => setOpenBurger(true)}
              >
                <IconBurger />
              </button>
            </div>
            <BurgerMenu
              setOpenBurger={setOpenBurger}
              openBurger={openBurger}
              serviceList={serviceList}
              ServiceItem={ServiceItem}
            />
          </div>
        </header>
      )}
    </Translation>
  );
};
